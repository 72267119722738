export const calculateTotalPrice = (
  rooms: RoomType[],
  prices: PriceType[],
  travelInsurances: number,
  annulationInsurance: number,
  bothInsurance: number
) => {
  let total = 0;
  rooms.forEach((x) => {
    const price = prices[x.size];
    total = total + Math.ceil(Number(price.discounted)) * (x.size + 1);
  });
  total = total + travelInsurances + annulationInsurance + bothInsurance;
  return Math.ceil(total);
};
