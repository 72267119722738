import React from "react";
import { MinIcon, PlusMathIcon } from "@travelquest/web-components";
import { useTranslation } from "react-i18next";
import {
  TravellerContent,
  TravellerObject,
  TravellerHeader,
  TextBlock,
  CardLine,
  Title,
} from "./Traveller.styled";
import { ContextType, useAppState } from "../../../store";
import { getPriceForAddon } from "../../../helpers";

type Props = {
  index: number;
  traveller: TravellerType;
  addons: Record<string, AddonType>;
  room: number;
  basePrice: PriceType;
  travelInsurancePrice: number;
  annulationInsurancePrice: number;
  bothInsurancePrice: number;
  isOpen: boolean;
  setIsOpen: (arg: number | null) => void;
  period: PeriodType;
};

export const Traveller = ({
  room,
  traveller,
  isOpen,
  basePrice,
  setIsOpen,
  travelInsurancePrice,
  annulationInsurancePrice,
  bothInsurancePrice,
  index,
  addons,
  period,
}: Props) => {
  const {
    appState: { brand },
  } = useAppState() as ContextType;
  const { country } = brand;
  const { t } = useTranslation(["traveller", "addons"]);
  const {
    hasTravelInsurance,
    hasAnnulationInsurance,
    hasBothInsurances,
    addons: travellerAddons,
    excursions,
    firstName,
    lastName,
    street,
    zip,
    city,
    country: travellerCountry,
    phone,
    email,
    registryNr,
    gender,
    dateOfBirth,
  } = traveller;
  let total = Math.ceil(basePrice?.discounted);
  if (hasTravelInsurance) {
    total += travelInsurancePrice;
  }
  if (hasAnnulationInsurance) {
    total += annulationInsurancePrice;
  }

  if (hasBothInsurances) {
    total += bothInsurancePrice;
  }

  excursions?.forEach((exc) => {
    total += exc.price;
  });

  travellerAddons?.forEach((id: string) => {
    total += getPriceForAddon(
      addons[id].pricing,
      addons[id].defaultPrice,
      period?.start
    );
  });

  return (
    <TravellerObject>
      <TravellerHeader onClick={() => setIsOpen(isOpen ? null : index)}>
        {`${firstName} ${lastName}`} {isOpen ? <MinIcon /> : <PlusMathIcon />}
      </TravellerHeader>
      {isOpen && (
        <TravellerContent>
          <Title>Gegevens</Title>
          <TextBlock style={{ textTransform: "capitalize" }}>
            {`${firstName} ${lastName}`}
            <br />
            {street}
            <br />
            {`${zip} ${city}`}
          </TextBlock>
          <TextBlock>
            <b>{t("traveller:phoneNumber")}:</b> {phone}
          </TextBlock>
          <TextBlock>
            <b>E-mail:</b> {email}
          </TextBlock>
          {country === "BE" && travellerCountry === "BE" && (
            <TextBlock>
              <b>Rijksregisternummer:</b> {registryNr}
            </TextBlock>
          )}
          <TextBlock>
            <b>Geslacht:</b> {gender}
          </TextBlock>
          <TextBlock>
            <b>Geboortedatum:</b> {dateOfBirth}
          </TextBlock>
          <CardLine />
          <Title>Reis overzicht</Title>
          <TextBlock>
            <b>Kamertype:</b> {room + 1}-persoonskamer €{" "}
            {Math.ceil(basePrice?.discounted)}
          </TextBlock>
          <TextBlock>
            <b>{t("addons:annulationInsurance")}:</b>{" "}
            {hasAnnulationInsurance
              ? `Ja €${annulationInsurancePrice}`
              : "Geen"}
          </TextBlock>
          <TextBlock>
            <b>{t("addons:travelinsurance")}:</b>{" "}
            {hasTravelInsurance ? `Ja €${travelInsurancePrice}` : "Geen"}
          </TextBlock>
          <TextBlock>
            <b>{t("addons:bothInsurance")}:</b>{" "}
            {hasBothInsurances ? `Ja €${bothInsurancePrice}` : "Geen"}
          </TextBlock>
          <TextBlock>{excursions?.length ? <b>Excursies:</b> : null}</TextBlock>
          {excursions?.map((exc, index) => (
            <TextBlock
              key={`exc-${index}`}
            >{`${exc.name}: € ${exc.price}`}</TextBlock>
          ))}
          {travellerAddons?.map((addonId) => (
            <TextBlock key={addonId}>
              {addons[addonId].name.nlbe}: €{" "}
              {getPriceForAddon(
                addons[addonId].pricing,
                addons[addonId].defaultPrice,
                period?.start
              )}
            </TextBlock>
          ))}

          <TextBlock>
            <b>Totaal:</b> €{total}
          </TextBlock>
        </TravellerContent>
      )}
    </TravellerObject>
  );
};
