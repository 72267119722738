import { gql } from "@apollo/client";
import { languageObject } from "./language";
import { Traveller } from "./traveller";

const price = `
bus {
  normal
  discounted
}
plane {
  normal
  discounted
}
self {
  normal
  discounted
}
`;

export const GET_BOOKING_BY_ID = gql`
  query booking($id: String!) {
    booking(id: $id) {
      id
      uniqueRoomId
      onDemand
      brand {
       id
       code
      }
      status
      referal
      targetAudience {
        id
        name {
          ${languageObject}
        }
      }
      orderNumber
      transport
      mealType
      airport {
        id
        name {
          ${languageObject}
        }
      }
     mainBooker {
       id
     }
      period {
        start
        prices {
          logies {
          ${price}
          }
          halfPension {
            ${price}
          }
          fullPension {
            ${price}
          }
          allInclusive {
            ${price}
          }
          breakfast {
            ${price}
          }
          allInclusiveAllDay {
            ${price}
          }
        }
        end
        roomAvailability
      }
      
      travellers {
       ${Traveller}
      }
      rooms {
        id
        price
        size
      }
      brandAccommodation {
        id
        targetAudiences {
            id
            name {
              ${languageObject}
            }
          }
        brandDestination {
          id
          destination {
            name {
              ${languageObject}
            }
          }
          airports {
            id
            name {
              ${languageObject}
            }
          }
          excursions {
            id
            name {
              ${languageObject}
            }
            type
            defaultPrice
            pricing {
              name {
                ${languageObject}
              }
              price
              periodStart
              periodEnd
            }
            bookable
            status
          }
          insurances {
            id
            name
            type
            price
            brand
            allDestinations
          }
          targetAudiences {
            id
            name {
              ${languageObject}
            }
          }
          addons {
            id
            name {
              ${languageObject}
            }
            info {
              ${languageObject}
            }
            defaultPrice
            tags
            categories
            pricing {
              name {
                ${languageObject}
              }
              price
              periodStart
              periodEnd
            }
          }
        }
          accommodation {
          name {
            ${languageObject}
          }
          roomSize {
            min
            max
          }
          id
          rating
          location
          coverImage {
            url
            alt
          }
        }
      }
    }
  }
`;

export const GET_TARGETAUDIENCES = gql`
  query TargetAudiences($brand: String!) {
    targetAudiences(brand: $brand) {
      id
      name {
        ${languageObject}
        nl
        fr
        en
      }
    }
  }
`;

export const UPDATE_BOOKING = gql`
  mutation updateBooking($input: BookingUpdateInput!) {
    updateBooking(input: $input) {
      message
      success
    }
  }
`;

export const SEND_CONFIRMATION_EMAIL = gql`
  mutation sendConfirmationEmail($input: ConfirmationInput!) {
    sendConfirmationEmail(input: $input) {
      success
      message
    }
  }
`;

export const SEND_INVOICE_EMAIL = gql`
  mutation sendInvoiceEmail($id: String!) {
    sendInvoiceEmail(id: $id) {
      success
      message
    }
  }
`;
